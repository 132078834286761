@font-face {
  font-family: "USBankCircular";
  src: url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Light.woff2")
      format("woff2"),
    url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: "USBankCircularWebLightItalic";
//   src:url("U.S.BankCircularWeb-LightItalic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-LightItalic.woff") format("woff");
// }
@font-face {
  font-family: "USBankCircular";
  src: url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: "USBankCircularWebItalic";
//   src:url("U.S.BankCircularWeb-Italic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-Italic.woff") format("woff");
// }
// @font-face {
//   font-family: "USBankCircularWebBook";
//   src:url("U.S.BankCircularWeb-Book.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-Book.woff") format("woff");
// }
// @font-face {
//   font-family: "USBankCircularWebBookItalic";
//   src:url("U.S.BankCircularWeb-BookItalic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-BookItalic.woff") format("woff");
// }
// @font-face {
//   font-family: "USBankCircularWebMedium";
//   src:url("U.S.BankCircularWeb-Medium.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-Medium.woff") format("woff");
// }
// @font-face {
//   font-family: "USBankCircularWebMediumItalic";
//   src:url("U.S.BankCircularWeb-MediumItalic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-MediumItalic.woff") format("woff");
// }
@font-face {
  font-family: "USBankCircular";
  src: url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Bold.woff2")
      format("woff2"),
    url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
// @font-face {
//   font-family: "USBankCircularWebBoldItalic";
//   src:url("U.S.BankCircularWeb-BoldItalic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-BoldItalic.woff") format("woff");
// }
@font-face {
  font-family: "USBankCircular";
  src: url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Black.woff2")
      format("woff2"),
    url("./assets/fonts/USBankCircular/U.S.BankCircularWeb-Black.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "USBankCircularWebBlackItalic";
//   src:url("U.S.BankCircularWeb-BlackItalic.woff2") format("woff2"),
//       url("U.S.BankCircularWeb-BlackItalic.woff") format("woff");
// }

body {
  margin: 0;
  padding: 0;
  font-family: "USBankCircular", arial, "Roboto", sans-serif;
  font-size: 14px;
  color: #000;
  background-color: #fbfbfb;
  font-weight: normal;
}
a {
  text-decoration: none;
}
.container {
  width: 100%;
}
input {
  font-family: "USBankCircular", arial, "Roboto", sans-serif;
}
.loader {
  &.padding &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.no-padding {
  padding: 0 !important;
}
.welcome-message {
  font-size: 25px;
  margin: 1rem 0rem 2rem 0rem;
  color: #827f7f;
}
.item-count {
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;
}
.button-normal {
  font-weight: 400 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.05px !important;
  opacity: 0.8;
}
ul.error {
  padding: 0;
  padding-left: 1rem;
  li {
    margin: 0.5rem 0rem;
  }
}
.inputLabel {
  font-size: 14px;
  font-weight: bold;
  margin: 0.5rem 0;
  display: block;
  color: #000;
}
.inputField {
  border: solid 1px #dfe1ef;
  background-color: #fff;
}
.react-datepicker-wrapper {
  width: calc(100% - 1rem);
  input {
    cursor: pointer;
  }
}
.flex-container {
  display: flex;
  &.vertical-center {
    align-items: center;
  }
  &.horizontal-center {
    justify-content: center;
  }
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.black {
  color: #222;
}
.white {
  color: #fff;
}
.list-title {
  font-size: 16px;
  letter-spacing: normal;
  color: #3f3f3f;
}
.file-status {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.05px;
  text-align: center;
  width: 140px;
  display: block;
  &.rejected {
    opacity: 1;
    background-color: #d8667f;
    color: #842438;
  }
  &.processed,
  &.approved {
    opacity: 0.87;
    background-color: #53caa7;
    color: #1b4c3e;
  }
  &.initiated,
  &.pending {
    opacity: 0.87;
    background-color: rgba(235, 166, 97, 0.72);
    color: #1b4c3e;
  }
}
.alert-message {
  background-color: rgb(255, 244, 229);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1225px !important;
  height: 49px;
  // margin-left: 20px;
  // padding: 16px 16px;
  color: rgb(102, 60, 0);
  border-radius: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  font-family: "USBankCircular", arial, "Roboto", sans-serif;
  margin-top: 25px;
  // margin-right: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
#supportTicket a:hover {
  text-decoration: none;
}
.react-datepicker {
  font-family: "USBankCircular", arial, "Roboto", sans-serif;
}
.MuiTableSortLabel-root:hover {
  color: #000 !important;
}
.alert-dialog-title {
  .MuiTypography-h6 {
    font-weight: bold;
    color: #000;
  }
}
.alert-dialog-action {
  button.Mui-disabled {
    border: none;
  }
}
.staticPos {
  position: static !important;
}

.noTopPad {
  padding-top: 0 !important;
}
