.leaderboard-table {
  width: 100%;
  border-radius: 0 !important;
}

.table-cell-head {
  height: auto !important;
  padding: 10px !important;
  background: #e6e6e6;
  color: #000000 !important;
  text-align: center !important;
}

.table-cell-body {
  height: 20px !important;
  padding: 14px !important;
  text-align: center !important;
}
#tableCell {
  font-size: 16px;
  font-weight: 500;
}

